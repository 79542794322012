var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M12 5.33301C12 4.27214 11.5786 3.25473 10.8284 2.50458C10.0783 1.75444 9.06087 1.33301 8 1.33301C6.93913 1.33301 5.92172 1.75444 5.17157 2.50458C4.42143 3.25473 4 4.27214 4 5.33301C4 9.99967 2 11.333 2 11.333H14C14 11.333 12 9.99967 12 5.33301Z",
          stroke: "white",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.15237 14C9.03516 14.2021 8.86693 14.3698 8.66452 14.4864C8.46211 14.6029 8.23262 14.6643 7.99904 14.6643C7.76545 14.6643 7.53596 14.6029 7.33355 14.4864C7.13114 14.3698 6.96291 14.2021 6.8457 14",
          stroke: "white",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }